import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Button from "../../components/ui/Button";
import { APIHandler } from "../../utils/axiosInstance";
import { clearCart } from '../../slices/cartSlice';
import { indianLocations } from "../../utils/statesAndUTs";
import { amountFormat } from '../../utils/amountFormat.js';

const ORDER_FORM = {
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  EMAIL: 'Email',
  PHONE: 'Phone Number',
  GENDER: 'Gender',
  GSTNO: 'GST Number',
  STREET: 'Street',
  HOUSE_NO: 'House Number',
  CITY: 'City',
  STATE: 'State',
  ZIPCODE: 'Zipcode',
  LANDMARK: 'Landmark',  
};


const INITIAL_FORM_VALUE = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  gender: '',
  gstNo: '',
  street: '',
  houseNo: '',
  city: '',
  state: '',
  zipcode: '',
  landMark: ''
};

const InputField = ({ label, name, type = 'text', value, onChange, error }) => (
  <div className="input_box">
    <label className="input_label">{label}</label>
    <input
      type={type}
      name={name}
      className="input_field"
      value={value}
      onChange={onChange}
      required
      aria-invalid={!!error}
    />
    {error && <span className='error'>{error}</span>}
  </div>
);

const SelectField = ({ label, name, options, value, onChange, error }) => (
  <div className="input_box">
    <label className="input_label">{label}</label>
    <select
      name={name}
      className="input_field"
      value={value}
      onChange={onChange}
      required
      aria-invalid={!!error}
    >
      <option value="">Select</option>
      {options.map((option, index) => (
        <option value={option} key={index}>{option}</option>
      ))}
    </select>
    {error && <span className='error'>{error}</span>}
  </div>
);

const OrderForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(INITIAL_FORM_VALUE);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [isCoupon, setIsCoupon] = useState(false);
  const dispatch = useDispatch();

  const cartItems = JSON.parse(localStorage.getItem('cartState'));

  useEffect(() => {
    if (cartItems.coupon && Object.keys(cartItems.coupon).length > 0) {
      setIsCoupon(true);
    } else {
      setIsCoupon(false);
    }
  }, [cartItems]);

  // Validation rules object
  const validationRules = {
    firstName: {
      required: true,
      pattern: /^[A-Za-z\s]+$/,
      minLength: 2,
      validate: (value) => {
        if (!value) return 'First Name is required';
        if (value.length < 2) return 'First Name must be at least 2 characters';
        if (!/^[A-Za-z\s]+$/.test(value)) return 'First Name must contain only letters';
        return '';
      }
    },
    lastName: {
      required: true,
      pattern: /^[A-Za-z\s]+$/,
      minLength: 2,
      validate: (value) => {
        if (!value) return 'Last Name is required';
        if (value.length < 2) return 'Last Name must be at least 2 characters';
        if (!/^[A-Za-z\s]+$/.test(value)) return 'Last Name must contain only letters';
        return '';
      }
    },
    email: {
      required: true,
      pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      validate: (value) => {
        if (!value) return 'Email is required';
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) return 'Invalid email address';
        return '';
      }
    },
    phone: {
      required: true,
      pattern: /^\d{10}$/,
      validate: (value) => {
        if (!value) return 'Phone number is required';
        if (!/^\d{10}$/.test(value)) return 'Phone number must be exactly 10 digits';
        return '';
      }
    },
    gender: {
      required: false,
      validate: (value) => {
        if (value && !['Male', 'Female', 'Other'].includes(value)) {
          return 'Please select a valid gender';
        }
        return '';
      }
    },
    gstNo: {
      required: false,
      pattern: /^[0-9]{15}$/,
      validate: (value) => {
        if (value && !/^[0-9]{15}$/.test(value)) {
          return 'GST Number must be exactly 15 digits';
        }
        return '';
      }
    },
    street: {
      required: true,
      minLength: 5,
      validate: (value) => {
        if (!value) return 'Street address is required';
        if (value.length < 5) return 'Street address must be at least 5 characters';
        return '';
      }
    },
    houseNo: {
      required: true,
      validate: (value) => {
        if (!value) return 'House Number is required';
        return '';
      }
    },
    city: {
      required: true,
      pattern: /^[A-Za-z\s]+$/,
      validate: (value) => {
        if (!value) return 'City is required';
        if (!/^[A-Za-z\s]+$/.test(value)) return 'City must contain only letters';
        return '';
      }
    },
    state: {
      required: true,
      validate: (value) => {
        if (!value) return 'State is required';
        if (!indianLocations.includes(value)) return 'Please select a valid state';
        return '';
      }
    },
    zipcode: {
      required: true,
      pattern: /^\d{5,6}$/,
      validate: (value) => {
        if (!value) return 'Zipcode is required';
        if (!/^\d{5,6}$/.test(value)) return 'Zipcode must be 5 or 6 digits';
        return '';
      }
    },
    landMark: {
      required: false,
      validate: (value) => ''
    }
  };

  // Validate single field
  const validateField = useCallback((name, value) => {
    const rule = validationRules[name];
    if (!rule) return '';
    
    return rule.validate(value?.trim());
  }, []);

  // Handle input change with validation
  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));

    // Validate field on change
    const error = validateField(name, value);
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: error
    }));
  }, [validateField]);

  // Validate entire form
  const validateForm = useCallback((data) => {
    const newErrors = {};
    
    // Validate each field
    Object.keys(validationRules).forEach(fieldName => {
      const error = validateField(fieldName, data[fieldName]);
      if (error) {
        newErrors[fieldName] = error;
      }
    });

    return newErrors;
  }, [validateField]);

  const handleFormSubmit = useCallback(async (e) => {
    e.preventDefault();

    const totalAmt = JSON.parse(localStorage.getItem('cartState')).totalAmount;
    
    // Validate all fields before submission
    const formErrors = validateForm(formData);
    
    if (Object.keys(formErrors).length === 0) {
      setLoading(true);
      setErrors({});
      setSuccess(null);

      const orderObj = {
        cartId: localStorage.getItem('cartId'),
        formData: formData,
        amount: totalAmt
      };

      try {
        const { data } = await APIHandler(
          "POST",
          "/order",
          JSON.stringify(orderObj),
          {
            "content-type": "application/json; charset=utf-8"
          }
        );
        
        setSuccess('Form submitted successfully!');
        setFormData(INITIAL_FORM_VALUE);
        
        const receipt = data.receipt;
        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY,
          amount: data.amount,
          currency: data.currency,
          name: 'VIR Bike',
          description: 'Payment for your selected bike',
          order_id: data.id,
          handler: async function (response) {
            const { data: verifyData } = await APIHandler(
              "POST",
              "/order/verify-payment",
              JSON.stringify({
                order_id: response.razorpay_order_id,
                payment_id: response.razorpay_payment_id,
                signature: response.razorpay_signature,
                receipt: receipt
              }),
              {
                "content-type": "application/json; charset=utf-8"
              }
            );
            
            if (verifyData.success) {
              alert('Payment Successful!');
              dispatch(clearCart());
              navigate('/order-received');
            } else {
              alert('Payment Verification Failed!');
              navigate('/payment-failed');
            }
          },
          prefill: {
            name: `${formData.firstName} ${formData.lastName}`,
            email: formData.email,
            contact: formData.phone,
          },
          theme: {
            color: '#093125',
          },
          modal: {
            ondismiss: function () {
              setLoading(false);
            },
          },
        };

        const paymentObject = new window.Razorpay(options);
        
        paymentObject.on('payment.failed', function (response) {
          console.error('Payment failed:', response.error);
          alert('Payment Failed. Please try again.');
          navigate('/payment-failed');
        });

        paymentObject.open();

      } catch (error) {
        setErrors({ form: 'An error occurred while submitting the form.' });
        setLoading(false);
      }
    } else {
      setErrors(formErrors);
      setLoading(false);
    }
  }, [formData, validateForm, navigate, dispatch]);

  return (
    <>
      <div>
        <div className='order-details'>
          <div className="order-details-top text-center d-flex flex-column gap-3 py-5">
            <p className='fs-5'>Total amount</p>
            <h2>₹{amountFormat(cartItems.totalAmount)}</h2>
            <div className='fs-5'>
              <span>
                <img loading="lazy" src="/images/icons/padlock.png" alt="secure payment" width={12} height={12}/>
              </span>
              <span className='px-2'>Secure Payment</span>
            </div>
            <hr />
          </div>
          <div className='order-details-bottom d-flex flex-column'>
            <p className='fs-5 mb-5 text-light'>Order Summary</p>
            {
              cartItems && cartItems.items.map(item => (
                <div className='mb-4'>
                  <div className='d-flex justify-content-between mb-3 fs-4 fw-bold text-dark-gray'>
                    <p>{item.product.productName} ({item.selectedColorOption}) x {item.bikeQuantity}</p>
                    <p>₹{amountFormat(item.product.pricing.originalPrice * item.bikeQuantity)}</p>
                  </div>
                  {
                    item.addons.length > 0 && item.addons.map(addon => (
                      <div className='d-flex justify-content-between mb-2 fs-5 text-light-gray'>
                        <p>{addon.addonName} x {addon.quantity}</p>
                        <p>₹{amountFormat(addon.originalPrice * addon.quantity)}</p>
                      </div>
                    ))
                  }                
                </div>
              ))
            }
            <hr />
          </div>
          <div className='d-flex justify-content-between fs-4 mb-3 text-light-gray'>
            <p>Subtotal</p>
            <p className='text-dark-gray fw-bold'>₹{amountFormat(cartItems.totalAmount)}</p>
          </div>
          { isCoupon && <div className='d-flex justify-content-between fs-4 mb-3 text-light-gray'>
            <p>Discount</p>
            <p className='text-dark-gray fw-bold'>₹{amountFormat(cartItems.coupon['discountValue'])}</p>
          </div> }
          <div className='d-flex justify-content-between fs-4 mb-3 text-light-gray'>
            <p>Shipping</p>
            <p className='text-dark-gray fw-bold'>Free</p>
          </div>
          <div className='d-flex justify-content-between fs-4 text-light-gray'>
            <p>Tax</p>
            <p className='text-dark-gray fw-bold'>Inc.</p>
          </div>
          <hr />
          <div className='d-flex justify-content-between fs-4 fw-bold'>
            <p>Total</p>
            <p>₹{amountFormat(cartItems.totalAmount)}</p>
          </div>
        </div>
      </div>

      {/* Form Section */}
      <div className="d-flex justify-content-start">
        <div className="form-wrap">
          <h2>BILLING & SHIPPING ADDRESS</h2>

          <form onSubmit={handleFormSubmit} noValidate>
            <div className="order-field-wrap">
              <InputField
                label={ORDER_FORM.FIRST_NAME}
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                error={errors.firstName}
              />            
              <InputField
                label={ORDER_FORM.LAST_NAME}
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                error={errors.lastName}
              />
            </div>

            <div className="order-field-wrap">
              <InputField
                label={ORDER_FORM.EMAIL}
                name="email"
                value={formData.email}
                onChange={handleChange}
                error={errors.email}
              />            
              <InputField
                label={ORDER_FORM.PHONE}
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                error={errors.phone}
              />
            </div>
            
            <div className="order-field-wrap">
              <SelectField
                label={ORDER_FORM.GENDER}
                name="gender"
                options={['Male', 'Female', 'Other']}
                value={formData.gender}
                onChange={handleChange}
                error={errors.gender}
              />            
              <InputField
                label={ORDER_FORM.GSTNO}
                name="gstNo"
                value={formData.gstNo}
                onChange={handleChange}
                error={errors.gstNo}
              />
            </div>
            
            <div className="order-field-wrap">
              <InputField
                label={ORDER_FORM.HOUSE_NO}
                name="houseNo"
                value={formData.houseNo}
                onChange={handleChange}
                error={errors.houseNo}
              />
              <InputField
                label={ORDER_FORM.STREET}
                name="street"
                value={formData.street}
                onChange={handleChange}
                error={errors.street}
              />
            </div>
            
            <div className="order-field-wrap">
              <InputField
                label={ORDER_FORM.CITY}
                name="city"
                value={formData.city}
                onChange={handleChange}
                error={errors.city}
              />            
              <SelectField
                label={ORDER_FORM.STATE}
                name="state"
                options={indianLocations}
                value={formData.state}
                onChange={handleChange}
                error={errors.state}
              />
            </div>

            <div className="order-field-wrap">
              <InputField
                label={ORDER_FORM.ZIPCODE}
                name="zipcode"
                type="text"
                value={formData.zipcode}
                onChange={handleChange}
                error={errors.zipcode}
              />            
              <InputField
                label={ORDER_FORM.LANDMARK}
                name="landMark"
                value={formData.landMark}
                onChange={handleChange}
                error={errors.landMark}
              />
            </div>

            <div className="order-field-wrap mt-5">
              <div className="razorpay">
                <span>Powered by</span>
                <img 
                  loading="lazy" 
                  src="/images/icons/razorpay.png" 
                  className="img-fluid" 
                  alt="razorpay" 
                />
              </div>
              <Button 
                type="submit" 
                className="paynow-btn" 
                disabled={loading}
              >
                {loading ? 'Processing...' : 'Make Payment'}
              </Button>
            </div>

            {success && (
              <div 
                aria-live="polite" 
                className="success-message mt-3"
              >
                {success}
              </div>
            )}
            
            {errors.form && (
              <div 
                aria-live="polite" 
                className="error-message mt-3"
              >
                {errors.form}
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default OrderForm;