import React, { useEffect } from "react";
import {
  ACHIEVEMENTS_SUB_SECTION_HEADING
} from "../../constants.js";
import SectionHeading from "../../components/ui/SectionHeading.js";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const KeyAchievements = () => {

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
  
    const t3 = gsap.timeline({
      scrollTrigger: {
        trigger: "#achievements-content",
        start: "top center",
        end: "top 20%",
      }
    }, { defaults: { duration: 1, ease: "power3.out" }});
  
    t3.from(".achievements-content-subHeading", {
      y: 20,
      opacity: 0
    })
    .from("#aic-img2", {
      y: 20,
      opacity: 0
    })
    .from(".acs-h4", {
      y: 20,
      opacity: 0,
      stagger: 0.2
    })
    .from(".achievements-content-text", {
      y: 20,
      opacity: 0
    })
    .from("#aic-img", {
      y: 20,
      opacity: 0
    })
  
    // Cleanup function to kill ScrollTrigger instances
    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  
   }, [])

  return (
    <section id="key-achievements-section">
      <div className="container achievements d-flex justify-content-center">
        <div id="achievements-content" className="achievements-content d-flex flex-column flex-lg-row">
          <div>
            <SectionHeading 
              heading={''} 
              headingClass={'achievements-content-heading'} 
              subHeading={ACHIEVEMENTS_SUB_SECTION_HEADING} 
              subHeadingClass={'achievements-content-subHeading'} />
              <h4 className="acs-h4 mt-5">VIR Bikes Cheers Lt Col Bharat Pannu on RAAM Conquest!</h4>
              <h4 className="acs-h4 mt-4">VIR Bikes is pumped to sponsor Lt Col Bharat Pannu as he tackles the brutal 2024 Race Across America (RAAM)! <span style={{'fontWeight': '400'}}><i>(Only four Indian's have completed this race)</i></span></h4>

              <div className="achievements-content-text">
                <p>This endurance legend, known for crushing Guinness World Records, perfectly embodies the VIR spirit with his relentless drive and ultra-cycling achievements. From conquering iconic Indian routes like Leh-Manali and the Golden Quadrilateral to battling it out in international races like RAAM, Lt Col Pannu inspires us all to push our limits.</p>
              </div>

              <div id="aic-img" className="achievements-img-container mt-5">
                <figure>
                  <img loading="lazy" className="achievements-img" src="/images/col.jpg" alt="" />
                </figure>
                <figcaption className="mt-3 text-center fs-4">
                  Lt Col Bharat Pannu (In action during race) 2024 Race Across America (RAAM) <br />
                  <b>Winning the Armed Forces Cup at RAAM 2024</b>
                </figcaption>
              </div>
          </div>
          <div className="d-flex flex-column-reverse flex-lg-column">
            <div id="aic-img2" className="achievements-img-container">
              <figure>
                <img loading="lazy" className="achievements-img" src="/images/pannu-sir.jpg" alt="" />
              </figure>
            </div>
            <div className="mt-5 mb-5">
              <h3>Why We Partner with Champions:</h3>
              <p>Sponsoring Lt Col Pannu reflects our commitment to supporting exceptional athletes who live and breathe excellence and passion. We're cheering him on as he journeys further, leaving an undeniable mark on the world of cycling.</p>
              <p>Join the Celebration! Follow Lt Col Pannu's incredible RAAM adventure and celebrate his achievements with the VIR community!</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default KeyAchievements;