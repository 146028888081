import React, { useState, useEffect } from 'react';
import { APIHandler } from '../../utils/axiosInstance.js';
import { GoogleMap, Marker, InfoWindow, useJsApiLoader } from '@react-google-maps/api';
import Button from "../../components/ui/Button";

const mapContainerStyle = {
  width: '100%',
  height: '100vh'
};

const center = {
  lat: 18.5203, 
  lng: 73.8567 // Default center of map (Pune)
};

const Stores = () => {
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [cities, setCities] = useState([]);
  const [filteredStores, setFilteredStores] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [infoWindowPosition, setInfoWindowPosition] = useState(null);
  const [infoWindowContent, setInfoWindowContent] = useState('');

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  useEffect(() => {
    const fetchStores = async () => {
      const res = await APIHandler(
        "GET",
        `/stores`,
        null,
        {
          "content-type": "application/json; charset=utf-8"
        },
      );

      if (res.status !== 200) {
        console.log("No data is present")
      } else {     
        setStores(res.data);
        setFilteredStores(res.data);

        // Automatically select the first store if available
        if (res.data.length > 0) {
          setSelectedStore(res.data[0]);
          setInfoWindowPosition({
            lat: res.data[0].coordinates.lat + 0.0002, // Add the offset to lat
            lng: res.data[0].coordinates.lng
          });
          setInfoWindowContent(res.data[0].address);
        }
      }
    }

    fetchStores();

    const fetchStoresByCity = async () => {
      const res = await APIHandler(
        "GET",
        `/stores/cities`,
        null,
        {
          "content-type": "application/json; charset=utf-8"
        },
      );

      if (res.status !== 200) {
        console.log("No data is present")
      } else {     
        setCities(res.data);
      }
    }

    fetchStoresByCity();

  }, []);

  const handleSearch = () => {
    const filtered = stores.filter(store => 
      store.storeName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      store.address.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredStores(filtered);
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
    if (event.target.value) {
      const filtered = stores.filter(store => store.city === event.target.value);
      setFilteredStores(filtered);
    } else {
      setFilteredStores(stores);
    }
  };

  const handleStoreClick = (store) => {
    const offset = 0.0002;  // You can adjust this value to control the vertical offset
    setSelectedStore(store);
    setInfoWindowPosition({
      lat: store.coordinates.lat + offset, // Add the offset to lat
      lng: store.coordinates.lng,
    });
    setInfoWindowContent(store.address);
  };

  // Clear search bar and reset everything
  const handleClearSearch = () => {
    setSearchQuery('');
    setFilteredStores(stores); // Reset the filtered stores to all
    if (stores.length > 0) {
      setSelectedStore(stores[0]); // Set first store as selected
      setInfoWindowPosition({
        lat: stores[0].coordinates.lat + 0.0002, // Add the offset to lat
        lng: stores[0].coordinates.lng,
      });
      setInfoWindowContent(stores[0].address);
    }
  };

  return (
    <div className="store">
      <div className="store-sidebar">
        <div className="store-search-bar w-100">
          <div className="search-wrapper">
            <input
              type="text"
              placeholder="Search stores..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            {searchQuery && (
              <span className="clear-search" onClick={handleClearSearch}>
                &#10005; {/* "X" mark */}
              </span>
            )}
          </div>
          <Button 
            onClick={handleSearch} 
            className={'feature-btn store-search'}
            style={{ marginLeft: '8px'}}>
            Search
          </Button>
        </div>
        <div className="city-dropdown my-4">
          <select onChange={handleCityChange}>
            <option value="">All Cities</option>
            {cities.map((city, index) => (
              <option key={index} value={city}>{city}</option>
            ))}
          </select>
        </div>
        <ul className="store-list">
          {filteredStores.map(store => (
            <li key={store._id} 
              onClick={() => handleStoreClick(store)} 
              className={`store-card ${selectedStore && selectedStore._id === store._id ? 'selected' : ''}`}>
              <div className="store-card-content">
                <h3 className="store-name">{store.storeName}</h3>
                <p className={`store-address ${selectedStore && selectedStore._id === store._id ? 'highlight' : ''}`}>
                  {store.address}
                </p>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <div className="map-container">
        { isLoaded ? (
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={selectedStore ? { lat: selectedStore.coordinates.lat, lng: selectedStore.coordinates.lng } : center}
            zoom={17}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            {filteredStores.map(store => (
              <Marker
                key={store._id}
                position={{ lat: store.coordinates.lat, lng: store.coordinates.lng }}
                onClick={() => {
                  setSelectedStore(store);
                  setInfoWindowPosition({ lat: store.coordinates.lat, lng: store.coordinates.lng });
                  setInfoWindowContent(store.address);
                }}
              />
            ))}

            {/* Display the InfoWindow on marker click */}
            {infoWindowPosition && (
              <InfoWindow
                position={infoWindowPosition}
                onCloseClick={() => setInfoWindowPosition(null)}
              >
                <div className="info-window-content">
                  <h3>{selectedStore ? selectedStore.storeName : ''}</h3>
                  <p>{infoWindowContent}</p>
                </div>
              </InfoWindow>
            )}
          </GoogleMap>
        ) : (
          <></>
        ) }
      </div>
    </div>
  );
};

export default React.memo(Stores);
