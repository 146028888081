import React, { useEffect } from "react";
import Button from "../../components/ui/Button.js";
import SectionHeading from "../../components/ui/SectionHeading.js";
import YouTubeVideo from './YouTubeVideo';
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import {
  ABOUT_HEADING,
  ABOUT_SUB_HEADING,
  ABOUT_SECTION_DESCRIPTION,
  READ_MORE
} from "../../constants.js";

gsap.registerPlugin(ScrollTrigger);

const AboutSection = () => {

  useEffect(() => {
    gsap.from("#about-container", {
      scrollTrigger: {
        trigger: "#about-container",
        start: "top 60%",
        end: "top 20%",
      },
      duration: 1, 
      y: 50, 
      opacity: 0, 
      ease: "power3.out"
    });

    gsap.from("#vir2-front", {
      scrollTrigger: {
        trigger: "#vir2-front",
        start: "top 60%",
        end: "top 20%",
      },
      duration: 1.5, 
      scale: 0.85,
      transformOrigin: "bottom center",
      ease: "power3.out"
    });

    // Cleanup function to kill all ScrollTriggers when component unmounts
    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);

  return (
    <section id="about-section" className="section pb-0" style={{'minHeight': 0}}>
      <div className="about-container-wrapper">
        <div id="about-container" className="about-container d-flex flex-column justify-content-around align-items-center align-items-xl-start">
          <div className="about-container-heading">
            <SectionHeading 
              heading={ABOUT_HEADING} 
              headingClass={''} 
              subHeading={ABOUT_SUB_HEADING} 
              subHeadingClass={'about-sub-heading'} />
          </div>
          <div className="about-container-text d-flex">
            <div className="content about-container-text-content">
              {ABOUT_SECTION_DESCRIPTION}
            </div>
            {/* <div className="about-container-links">
              <Button className={'about-btn'} onClick={() => {}}>
                {READ_MORE}
              </Button>
            </div> */}
          </div>
        </div>
        <figure id="vir2-front" className="vir2-front">
          <img loading="lazy" src="/images/about_home.png" className="img-fluid w-100" alt="About VIR BIKE" />
        </figure>
        {/* <div className="youtube-container">
          <YouTubeVideo />
        </div> */}
      </div>
    </section>
  )
}

export default AboutSection;