import React, { useEffect } from "react";
import ContactDetails from "./ContactDetails.js";
import ContactForm from "./ContactForm.js";
import SectionHeading from "../../components/ui/SectionHeading.js";

import {
  CONTACT_HEADING,
  CONTACT_SUB_HEADING,
  CONTACT_SECTION_DESCRIPTION
} from "../../constants.js";
import Seo from '../../components/common/Seo.js';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Contact = () => {

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
  
    const t5 = gsap.timeline({
      scrollTrigger: {
        trigger: "#contact-heading",
        start: "top 60%",
        end: "top 20%",
      }
    }, { defaults: { duration: 1, ease: "power3.out" }});
  
    t5.from("#contact-heading", {
      y: 20,
      opacity: 0
    })
    .from("#contact-desc", {
      y: 20,
      opacity: 0
    })

    const t6 = gsap.timeline({
      scrollTrigger: {
        trigger: "#contact-form-wrap",
        start: "top 60%",
        end: "top 20%",
      }
    }, { defaults: { duration: 1, ease: "power3.out" }});
  
    t6.from("#contact-form-wrap", {
      y: 20,
      opacity: 0
    })
    .from(".contact-details", {
      y: 20,
      opacity: 0,
      stagger: 0.2
    })


  
    // Cleanup function to kill ScrollTrigger instances
    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  
   }, [])

  return (
    <>
      <Seo 
        title="Contact VIR BIKE - We're Here to Help"
        description="Have questions or need support? Contact VIR BIKE for any inquiries about our products, services, or community. We're here to assist you."
        name="VIR MOBILITY PVT. LTD"
        type="webapp"
        />
      <section id="contact" className="section">
        <div className="contact-container container d-flex flex-column">
          <div id="contact-heading">
            <SectionHeading 
              heading={CONTACT_HEADING} 
              headingClass={''} 
              subHeading={CONTACT_SUB_HEADING} 
              subHeadingClass={''} />
          </div>
          <div id="contact-desc" className="contact-description">
            {CONTACT_SECTION_DESCRIPTION}
          </div>
          <div className="form-and-details">
            <ContactForm />
            <ContactDetails />
          </div>
        </div>
      </section>
    </>
  )
}

export default Contact;