import React from 'react';
import { Link } from 'react-router-dom';

const FOOTER_MENU = [
  { id: 1, menu: 'Home', path: '/' },
  { id: 2, menu: 'About Us', path: '/about-us'  },
  { id: 3, menu: 'Community', path: '/vir-community'  },
  { id: 4, menu: 'Book a Test Ride', path: '/contact-us'  },
  { id: 5, menu: 'Store Locator', path: '/stores'  },
]

const HELP_MENU = [
  { id: 1, menu: 'Privacy Policy', path: '/privacy-policy' },
  { id: 2, menu: 'Terms and Conditions', path: '/terms-and-condition' },
  { id: 3, menu: 'Warranty and Refund', path: '/warranty-and-refund-policy' },
  { id: 4, menu: 'Trade-in Policy', path: '/trade-in-policy' },
  { id: 5, menu: 'Cookies Policy', path: '/cookies-policy' },
]

const CONTACT_MENU = [
  { id: 1, menu: 'pedal@virbike.com', href:"mailto:pedal@virbike.com" },
  { id: 2, menu: '+91-9119440258', href: "tel:+91-9119440258" },
]

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer-container container">
        <div className='footer-menu'>
          <figure>
            <img loading="lazy" style={{'width': '180px'}} src="/logo.png" alt="VIR BIKE logo" />
          </figure>
          <p className='mt-2'>
            679/2/2, Kuruli<br/>
            Chakan, Alandi Road,<br/>
            Pune-410501, Maharashtra, India
          </p>
          <br />
          <p>Made with ❤️ in India</p>
        </div>
        <div className='footer-menu'>
          <h2>Explore</h2>
          <ul className='p-0 footer-menu-list'>
            {
              FOOTER_MENU.map(item => (
                <li key={item.id+'fm'}>
                  <Link to={item.path}>{item.menu}</Link>
                </li>
              ))
            }
          </ul>
        </div>
        <div className='footer-menu'>
          <h2>Help</h2>
          <ul className='p-0 footer-menu-list'>
            {
              HELP_MENU.map(item => (
                <li key={item.id+'hm'}>
                  <Link to={item.path}>{item.menu}</Link>
                </li>
              ))
            }
          </ul>
        </div>
        <div className='footer-menu'>
          <h2>Contact</h2>
          <ul className='p-0 footer-menu-list'>
          {
            CONTACT_MENU.map(item => (
                <li key={item.id+'cm'}>
                  <a href={item.href}>{item.menu}</a>
                </li>
              ))
            }
          </ul>
        </div>
      </div>
      <div className="container d-flex flex-column flex-md-row justify-content-start my-5">
        <div><img loading="lazy" src="/images/bhau.jpeg" alt="" width={100}/></div>
        <div><img loading="lazy" className='mx-4' src="/images/startup-india.png" alt="" width={160}/></div>
        <div><img loading="lazy" src="/images/msme.jpeg" alt="" width={160}/></div>        
      </div>
      <div className='footer-copyright container d-grid'>
        <div className='mt-5'>
          © 2025. VIR Mobility PVT Ltd. All Rights Reserved
        </div>
        <div className='d-flex justify-content-start justify-content-md-end mt-5'>
          <span className="fw-bold founder-details-name mx-4">
            <a href="https://m.facebook.com/profile.php?id=100090789653971" target="_blank" rel="noopener noreferrer">
              <img loading="lazy" src="/images/icons/facebook.png" alt="" width={20} height={20} />
            </a>
          </span> 
          <span className="fw-bold founder-details-name mx-4">
            <a href="https://x.com/virbike?lang=en" target="_blank" rel="noopener noreferrer">
              <img loading="lazy" src="/images/icons/twitter.png" alt="" width={20} height={20} />
            </a>
          </span> 
          <span className="fw-bold founder-details-name mx-4">
            <a href="https://youtube.com/@virbike_official?si=tH9-VqawETwfDnSV" target="_blank" rel="noopener noreferrer">
              <img loading="lazy" src="/images/icons/youtube.png" alt="" width={20} height={20} />
            </a>
          </span> 
          <span className="fw-bold founder-details-name mx-4">
            <a href="https://www.instagram.com/virbike_official?igsh=dHVmd2VrcWFoaW5r&utm_source=qr" target="_blank" rel="noopener noreferrer">
              <img loading="lazy" src="/images/icons/instagram.png" alt="" width={20} height={20} />
            </a>
          </span> 
          <span className="fw-bold founder-details-name mx-4">
            <a href="https://in.linkedin.com/company/virbike" target="_blank" rel="noopener noreferrer">
              <img loading="lazy" src="/images/icons/linkedin.png" alt="" width={20} height={20} />
            </a>
          </span> 
        </div>
      </div>
    </footer>
  )
}

export default Footer;