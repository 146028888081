import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/ui/Button.js";
import SectionHeading from "../../components/ui/SectionHeading.js";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import {
  FEATURE_SECTION_HEADING,
  FEATURE_SECTION_SUB_HEADING,
  FEATURE_SECTION_DESCRIPTION,
  READ_MORE
} from "../../constants.js";

const FEATURE_DATA = [
  {
    id: 1,
    title: "Motor (BLDC)",
    content: "Throttle and Walk Assist Speed limited with",
    feature: "25 Kmph*"
  },
  {
    id: 2,
    title: "Charger (AC)",
    content: "Portable, Home charging socket",
    feature: "2.5 Hrs*"
  },
  {
    id: 3,
    title: "Display (LCD)",
    content: "Multifunctional with speed and range indicator",
    feature: "5 Level PAS"
  },
  {
    id: 4,
    title: "Battery (Detachable)",
    content: "Intube Battery",
    feature: "Upto 55 Km*"
  },
];


const FeatureSection = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const featureCards = gsap.utils.toArray("#features-item");

    const t1 = gsap.timeline({
      scrollTrigger: {
        trigger: "#fs-heading",
        start: "top 60%",
        end: "top 20%",
      },
    }, { defaults: { duration: 1, ease: "power3.out" } });

    t1.from("#fs-heading", {
      y: 20,
      opacity: 0,
    })
    .from(featureCards, {
      y: 20,
      opacity: 0,
      stagger: 0.2
    })
    .from(".fs-links", {
      y: 20,
      opacity: 0
    });

    gsap.from(".v3-sideview", {
      scrollTrigger: {
        trigger: ".v3-sideview",
        start: "top 60%",
        end: "top 20%"
      },
      duration: 1.5,
      scale: 0.85,
      transformOrigin: "bottom center",
      ease: "power3.out"
    });

    // Cleanup function to kill ScrollTrigger instances
    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);

  return (
    <section id="feature-section" className="section pb-0">
      <div className="container-fluid d-flex flex-column flex-lg-row justify-content-end">
        <div className="feature-section-content d-flex flex-column align-items-start justify-content-center">
          <div className="features-wrapper">
            <div className="d-flex flex-column justify-content-center gap-5">
              <div id="fs-heading" className="fs-heading">
                <SectionHeading 
                  heading={''} 
                  headingClass={''} 
                  subHeading={FEATURE_SECTION_SUB_HEADING} 
                  subHeadingClass={''} />
                <div className="content d-none">
                  {FEATURE_SECTION_DESCRIPTION}
                </div>
              </div>
              <div className="features">
                {FEATURE_DATA.map(item => {
                  return (
                    <div id="features-item" className="features-item" key={item.id+'feature'}>
                      <div className="features-item_title">
                        <span>{item.title}</span>
                      </div>
                      <div className="features-item_content">
                        {item.content}
                      </div>
                      <div className="features-item_feature">
                        {item.feature}
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className="feature-container-links fs-links">
                <Button className={'feature-btn'} onClick={() => {navigate(`/product-listing`)}}>
                  SHOP NOW
                </Button>
              </div>
            </div>
            <div>
              <figure className="v3-sideview">
                <img loading="lazy" src="/images/feature-sec.png" className="img-fluid w-100" alt="About VIR BIKE" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeatureSection;