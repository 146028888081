import React from 'react';

const WhatsAppButton = () => {
  const phoneNumber = '9119440258';
  const message = 'Hello, I need assistance!';

  const handleClick = () => {
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  return (
    <button className="whatsapp-button" onClick={handleClick}>
      <img loading="lazy" src="/images/whatsapp-icon.webp" alt="" width={40} height={40} />
    </button>
  );
};

export default WhatsAppButton;
