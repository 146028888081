import React, { useState } from 'react';
import { APIHandler } from '../../utils/axiosInstance';

import Button from '../../components/ui/Button';
import { toast } from "react-toastify";

const TEST_RIDE_FORM = {
  'NAME': 'Full Name',
  'PHONE': 'Phone Number',
}

const INITIAL_FORM_VALUE = {
  name: '',
  phone: ''
}

const TestRide = () => {
  const [formData, setFormData] = useState(INITIAL_FORM_VALUE);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm(formData);
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      setErrors({});

      try {
        const res = await APIHandler(
          "POST",
          "/testride",
          formData,
          {
            "content-type": "application/json; charset=utf-8"
          },
        );
        setFormData(INITIAL_FORM_VALUE);
        toast.success(`Form submitted successfully!`);
      } catch (error) {
        setErrors({ form: 'An error occurred while submitting the form.' });
      } finally {
        setLoading(false);
      }
    } else {
      setErrors(errors);
    }
  };

  const validateForm = (data) => {
    const errors = {};
    if (!data.name.trim()) {
      errors.name = 'Name is required';
    }

    if (!/^\d{10}$/.test(data.phone.trim())) {
      errors.phone = 'Phone number must be 10 digits';
    }

    return errors;
  };

  return (
    <div className='tr-container section d-flex flex-column justify-content-center align-items-center'>
      <div className="d-flex justify-content-start">
        <div id='tr-form-wrap' className="form-wrap d-flex flex-column align-items-center">
          <div className='text-center'>
            <h2>Book your test ride now.</h2>
            <p className='tr-container-message mb-3'>
              Uncover the perfect electric cycle for your daily commute and thrilling adventures. Experience the VIR Bike electric bicycle today!
            </p>
          </div>
          <form onSubmit={handleFormSubmit} className='tr-container-wrap mt-5'>
            <div className="input_box">
              <input
                type="text"
                name="name"
                className="input_field"
                placeholder='Full Name'
                value={formData.name}
                onChange={handleChange}
                required
              />
              {errors.name && <span className='error'>{errors.name}</span>}
            </div>

            <div className="input_box">
              <input
                type="text"
                name="phone"
                className="input_field"
                placeholder='Phone Number'
                value={formData.phone}
                onChange={handleChange}
                required
              />
              {errors.phone && <span className='error'>{errors.phone}</span>}
            </div>

            <Button type="submit" className={'feature-btn tr-btn mt-5'} disabled={loading}>
              {loading ? 'Submitting...' : 'Book Test Ride'}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default TestRide;