import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const DrivesUs = () => {

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
  
    const t3 = gsap.timeline({
      scrollTrigger: {
        trigger: "#drivesUs",
        start: "top center",
        end: "top 20%",
      }
    }, { defaults: { duration: 1, ease: "power3.out" }});
  
    t3.from("#drivesUs-img", {
      y: 20,
      opacity: 0
    })
    .from(".drivesUs-caption", {
      y: 20,
      opacity: 0
    }, "<0.25")
    .from("#duc-img", {
      y: 20,
      opacity: 0
    }, "<0.25")
    .from(".drivesUs-card-content", {
      y: 20,
      opacity: 0
    }, "<0.25")
  
    // Cleanup function to kill ScrollTrigger instances
    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  
   }, [])

  return (
    <section id="drives-us-section">
      <div id="drivesUs" className="drivesUs">
        <div>
          <figure id="drivesUs-img" className="img-container w-100">
            <img loading="lazy" src="/images/drivesUp.jpg" alt="" className="w-100 img-fluid" />
          </figure>
          <div className="drivesUs-caption">
            <h2>Turn your Passion<br/>into Motion</h2>
          </div>
        </div>
        <div className="drivesUs-card">
          <figure id="duc-img" className="w-100 d-none d-lg-block">
            <img loading="lazy" src="/images/drivesUp_v3.jpg" alt="" className="w-100 img-fluid" />
          </figure>
          <div className="drivesUs-card-content">
            <h4 className="text-dark-gray">Join the VIR BIKE Journey</h4>
            <p className="text-light-gray">
              Where Your Passion Drives Tomorrow's Adventures
            </p>
            <div className="mt-3 fs-5 fw-bold">
              <Link to={'/product-listing'}>SHOP NOW &#8594;</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DrivesUs;