import React, { useState, useEffect } from 'react';
import {
  FAQ_SECTION_HEADING,
  FAQ_SECTION_SUB_HEADING
} from "../../constants.js";
import SectionHeading from './SectionHeading.js';
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const FAQ_DATA = [
  {
    id: 1,
    question : 'How far can I ride on a single charge with a VIR electric cycle?',
    answer: 'Our VIR electric cycles offer a range of up to55km to 75km on a single charge, depending on the model and terrain. Learn more about battery life and how to maximize your riding distance.'
  },
  {
    id: 2,
    question : 'How long does it take to charge the battery of a VIR electric cycle?',
    answer: 'Charging time for VIR electric cycle batteries typically is 0-80% for 2.5 hours. Discover our fast-charging options and tips for efficient battery care.'
  },
  {
    id: 3,
    question : 'Do I need a license to ride an electric cycle in India?',
    answer: 'No license is required to ride our VIR electric cycles in India. They are classified as electric bicycles under low-speed category vehicles, making them accessible to everyone. Find out more about legal requirements.'
  },
  {
    id: 4,
    question : 'What are the key features of the VIR Desert Raptor electric cycle?',
    answer: 'The VIR Desert Raptor boasts a lightweight aluminum alloy frame, a 250W hub motor, a 13Ah Li-ion battery, and premium European-inspired design. Explore the full specifications and features that make it perfect for urban and leisure riding.'
  }
]

const FAQ = () => {
  const [openIndexes, setOpenIndexes] = useState([]);

  const toggleItem = (index) => {
    setOpenIndexes((prevIndexes) => {
      if (prevIndexes.includes(index)) {
        return prevIndexes.filter((item) => item !== index);
      } else {
        return [...prevIndexes, index];
      }
    });
  };

  useEffect(() => {
    // Get the faq content block elements
    const faqCards = gsap.utils.toArray("#faq-content-block");

    // Animation for the FAQ wrapper
    gsap.from("#faq-section #faq-content-wrapper", {
      scrollTrigger: {
        trigger: "#faq-section #faq-content-wrapper",
        start: "top 60%",
        end: "top 20%",
      },
      duration: 1, 
      y: 20, 
      opacity: 0, 
      ease: "power3.out"
    });

    // Animation for the individual FAQ cards
    gsap.from(faqCards, {
      opacity: 0,
      y: 20,
      duration: 1,
      ease: "easeOut",
      stagger: 0.2,
      scrollTrigger: {
        trigger: "#faq-content-block",
        start: "top 80%",
        end: "top 60%",
      },
    });

    // Cleanup function to kill any animations when the component unmounts
    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill()); // Removes any ScrollTriggers
      gsap.killTweensOf(faqCards); // Kills all GSAP tweens for FAQ cards
    };
  }, []);

  return (
    <section id="faq-section" className='section' style={{paddingTop: '0'}}>
      <div id="faq-content-wrapper" className="faq-content-wrapper d-flex flex-column justify-content-center align-items-center align-self-center">
        <SectionHeading
          heading={FAQ_SECTION_HEADING} 
          headingClass={''} 
          subHeading={FAQ_SECTION_SUB_HEADING} 
          subHeadingClass={''} />
          <div className="faq-content">
            {
              FAQ_DATA.map((item, index) => (
                <div id="faq-content-block" className="faq-content-block" key={index}>
                  <div className="faq-content-block_question text-dark-gray">
                    {item.question}
                  </div>
                  <div className="faq-content-block_answer text-light-gray">
                    {item.answer}
                  </div>
                </div>
              ))
            }
          </div>
      </div>
    </section>
  );
}

export default FAQ;
