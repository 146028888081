import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { fetchProducts } from '../services/productAPIHandler';

const NAVIGATION_ITEMS = [
  { id: 1, title: 'About Us', path: '/about-us' },
  {
    id: 2, title: 'Our Products', path: '#', dropdown: [
      { id: 1, title: 'Vir V1', path: '' },
      { id: 2, title: 'Vir V2', path: '' },
      { id: 3, title: 'Vir V3', path: '' }
    ]
  },
  { id: 3, title: 'Vir Community', path: '/vir-community' },
  { id: 4, title: 'Contact Us', path: '/contact-us' }
];

const Header = ({ onHeightChange }) => {
  const [expanded, setExpanded] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(null);  // Track dropdown open for each menu item
  const headerRef = useRef(null);
  const { items } = useSelector(state => state.cart);
  const navigate = useNavigate();
  const { products, isLoading } = useSelector(state => state.products); // Redux store data
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProducts()); // Fetch products on component mount
  }, [dispatch]);

  useEffect(() => {
    if (headerRef.current) {
      onHeightChange(headerRef.current.offsetHeight); // Adjust height based on header layout
    }
  }, [onHeightChange]);

  useEffect(() => {
    // Update dropdown paths dynamically once products are fetched
    NAVIGATION_ITEMS[1].dropdown.forEach(item => {
      const product = products.find(p => p.prettyUrl.includes(item.title.replace("Vir ", "").toLowerCase()));
      if (product) {
        item.path = `/product/${product.prettyUrl}`;
      }
    });
  }, [products]); // Run this whenever `products` data changes

  // Toggle navbar for mobile view
  const toggleNavbar = () => setExpanded(prev => !prev);

  // Handle link click and manage dropdown visibility
  const handleLinkClick = (item, isSubMenu = false) => {
    if (isSubMenu) {
      setDropdownOpen(null); // Close dropdown when sub-item clicked
      setExpanded(false); // Close the navbar (mobile menu) when sub-item clicked
    } else if (item.dropdown) {
      // Toggle dropdown for the current item (Our Products)
      setDropdownOpen(dropdownOpen === item.id ? null : item.id);
    } else {
      // Close navbar (mobile menu) if it's not a dropdown item
      if (expanded) setExpanded(false);
    }
  };

  // Navigate to product based on title
  const navigateToProduct = (title) => {
    const product = products.find(p => p.prettyUrl.includes(title.replace("Vir ", "").toLowerCase()));
    if (product) {
      localStorage.setItem("navigatingTo", product._id); // Store product ID
      setTimeout(() => {
        navigate(`/product/${product.prettyUrl}`);
      }, 300);
    }
  };

  return (
    <nav ref={headerRef} className="navbar navbar-expand-lg py-3 navbar-dark fixed-top bg-dark" data-bs-theme="dark">
      <div className="container-fluid navbar-container">
        <Link to="/" className="navbar-brand">
          <img src="/logo.png" alt="Logo" />
        </Link>

        <div className="cart-and-toggler-container ms-auto d-block d-lg-none px-5">
          <div className="navbar-nav_actions">
            <div className="navbar-nav_action">
              <Link to="/cart">
                <img src="/images/icons/cart.png" alt="Cart" width={24} height={24} />
                <div className="cart-length">
                  <h6>{items?.length}</h6>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded={expanded ? 'true' : 'false'} aria-label="Toggle navigation" onClick={toggleNavbar}>
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className={`collapse navbar-collapse justify-content-center ${expanded ? 'show' : ''}`} id="navbarNav">
          <ul className="navbar-nav d-flex justify-content-center mx-auto">
            {NAVIGATION_ITEMS.map(item => (
              <li className="nav-item" key={item.id}>
                <Link
                  to={item.path}
                  onClick={() => handleLinkClick(item)} // Unified onClick handler
                  className="nav-link"
                >
                  {item.title}
                  {/* Dropdown Icon for 'Our Products' */}
                  {item.dropdown && (
                    <i className={`fas fa-chevron-down ms-3 ${dropdownOpen === item.id ? 'rotate-180' : ''}`}></i>
                  )}
                </Link>
                {/* Dropdown for 'Our Products' */}
                {item.dropdown && dropdownOpen === item.id && (
                  <ul className="dropdown-menu show">
                    {item.dropdown.map(subItem => (
                      <li key={subItem.id} >
                        <Link
                          to={subItem.path || '#'} // Use dynamically set path
                          className="dropdown-item"
                          style={{ 'padding' : '8px 16px', 'fontSize': '14px'}}
                          onClick={() => { 
                            handleLinkClick(item, true); 
                            navigateToProduct(subItem.title); // Navigate to product page
                          }}
                        >
                          {subItem.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
          <div className="cart-and-toggler-container align-left">
            <div className="navbar-nav_actions">
              <div className="navbar-nav_action">
                <Link
                  to="/testride"
                  className="nav-link test-ride-btn"
                  onClick={() => setExpanded(false)} // Close the mobile menu on link click
                >
                  Book a Test Ride
                </Link>
              </div>
            </div>
          </div>
          <div className="cart-and-toggler-container align-left d-none d-lg-block" style={{'paddingLeft' : '16px'}}>
            <div className="navbar-nav_actions">
              <div className="navbar-nav_action">
                <Link to="/cart">
                  <img src="/images/icons/cart.png" alt="Cart" width={24} height={24} />
                  <div className="cart-length">
                    <h6>{items?.length}</h6>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
