import React, { useEffect, useState, useLayoutEffect  } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { initializeSession } from './utils/session.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation
} from 'react-router-dom';

import Header from './layouts/Header.js';
import Home from './pages/Home/Home.js';
import Footer from './layouts/Footer.js';
import ScrollToTopButton from './components/ui/ScrollToTopButton.js';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy.js';
import TermsAndCondition from './pages/TnC/TermsAndConditions.js';
import WarrantyAndRefuncdPolicy from './pages/WarrantyAndRefundPolicy/warrantyAndRefuncdPolicy.js';
import CookiesPolicy from './pages/CookiesPolicy/CookiesPolicy.js';
import About from './pages/About/About.js';
import Contact from './pages/Contact/Contact.js';
import ProductDetails from './pages/ProductDetails/ProductDetails.js';
import ProductListing from './pages/ProductListing/ProductListing.js';
import Community from './pages/Community/Community.js';
import Cart from './pages/Cart/Cart.js';
import { fetchAddons } from './services/addonAPIHandler.js';
import Order from './pages/Order/Order.js';

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import NotFound from './pages/NotFound/NotFound.js';
import ThankYouPage from './pages/Order/ThankyouPage.js';
import TradeInPolicy from './pages/TradeInPolicy/TradeInPolicy.js';
import WhatsAppButton from './components/common/WhatsAppButton.js';
import Stores from './pages/Stores/Stores.js';
import TestRide from './pages/TestRide/TestRide.js';

function App() {
  const [headerHeight, setHeaderHeight] = useState(0);

  const handleHeightChange = (height) => {
    setHeaderHeight(height);
  };

  const location = useLocation();

  useLayoutEffect(() => {
    // Scroll to top whenever the route changes
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    // Initialize session when the component mounts
    initializeSession();
    fetchAddons();
  }, []);



  
  return (
    <>
      <HelmetProvider>
        <Header onHeightChange={handleHeightChange}/>
        <div className='root-container' style={{ 'marginTop': headerHeight }}>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about-us' element={<About />} />
            <Route path='/vir-community' element={<Community />} />
            <Route path='/contact-us' element={<Contact />} />
            <Route path='/product-listing' element={<ProductListing />} />
            <Route path='/product/:productId' element={<ProductDetails />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/terms-and-condition' element={<TermsAndCondition />} />
            <Route path='/warranty-and-refund-policy' element={<WarrantyAndRefuncdPolicy />} />
            <Route path='/cookies-policy' element={<CookiesPolicy />} />
            <Route path='/trade-in-policy' element={<TradeInPolicy />} />
            <Route path='/cart' element={<Cart />} />
            <Route path='/order' element={<Order />} />
            <Route path='/order-received' element={<ThankYouPage />} />
            <Route path='/stores' element={<Stores />} />
            <Route path='/testride' element={<TestRide />} />
            <Route path='*' element={<NotFound />} />
          </Routes>
          <Footer />
        </div>
        <ToastContainer
          position= "top-center"
          autoClose= {2000}
          hideProgressBar= {true}
          closeOnClick= {true}
          pauseOnHover= {true}
          draggable= {false}
          theme= "light"
        />
        <WhatsAppButton />
        <ScrollToTopButton />
      </HelmetProvider>
    </>
  );
}

export default App;
