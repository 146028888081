import React, { useEffect } from "react";  
import SectionHeading from "../../components/ui/SectionHeading.js";
import {
  LEADERSHIP_SECTION_HEADING,
  LEADERSHIP_SUB_SECTION_HEADING
} from "../../constants.js";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";


const Leadership = () => {

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
  
    const t3 = gsap.timeline({
      scrollTrigger: {
        trigger: "#lc-content",
        start: "top 60%",
        end: "top 20%",
      }
    }, { defaults: { duration: 1, ease: "power3.out" }});
  
    t3.from("#lc-content", {
      y: 20,
      opacity: 0
    })
    .from(".leadership-container-content_img", {
      y: 20,
      opacity: 0
    })
  
    // Cleanup function to kill ScrollTrigger instances
    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  
   }, [])

  return ( 
    <section id="leadership-section">
      <div className="container leadership-container">
        <div className="leadership-container-content">
          <div id="lc-content">
            <SectionHeading 
              heading={LEADERSHIP_SECTION_HEADING} 
              headingClass={''} 
              subHeading={LEADERSHIP_SUB_SECTION_HEADING} 
              subHeadingClass={''} />
          </div>
          <div 
            className="leadership-container-content_img d-flex flex-column flex-lg-row">
            <div>
              <figure>
                <img loading="lazy"
                src="/images/founder/founder.jpg" alt="Ravi Kumar" />
                <figcaption className="mt-3 text-center founder-details">
                  <span>
                    <span>
                      <span className="fw-bold founder-details-name">
                        <a href="https://www.linkedin.com/in/raviudchalo/" target="_blank" rel="noopener noreferrer">Ravi Kumar </a>
                      </span> 
                      <span className="founder-details-post">(Co-Founder and COO), </span>
                    </span>
                    <span>
                      <span className="fw-bold founder-details-name">
                        <a href="https://www.linkedin.com/in/rahul-gurung-11b59b1b/" target="_blank" rel="noopener noreferrer">Rahul Gurung </a>
                      </span> 
                      <span className="founder-details-post">(Co-Founder and CEO), </span>
                    </span>
                    <span>
                      <span className="fw-bold founder-details-name">
                        <a href="https://www.linkedin.com/in/sahil-uttekar-909b861a0/" target="_blank" rel="noopener noreferrer">Sahil Uttekar </a>
                      </span> 
                      <span className="founder-details-post">(Co-Founder and CTO)</span>
                    </span>
                  </span>
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Leadership;