import React, { useState, useEffect } from "react";
import ProductCard from "../../components/ui/ProductCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts } from "../../services/productAPIHandler";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Seo from "../../components/common/Seo";

const ProductListing = () => {
  const [prodType, setProdType] = useState('bike');
  const { products } = useSelector((state) => state.products);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProducts());
  }, []);

 useEffect(() => {
  gsap.registerPlugin(ScrollTrigger);

  const productCards = gsap.utils.toArray(".prod-card");

  const t2 = gsap.timeline({
    scrollTrigger: {
      trigger: ".prod-listing-wrapper",
      start: "top 70%",
      stop: "top 20%",
    }
  }, { defaults: { duration: 1, ease: "power3.out" }});

  t2.from(productCards, {
    y: 20,
    opacity: 0,
    stagger: 0.2
  })

  return () => {
    ScrollTrigger.getAll().forEach(trigger => trigger.kill());
  }
 }, []);

  return (
    <>
      <Seo 
        title="VIR BIKE Electric Cycles - Made with love in India"
        description="Discover VIR BIKE's premium electric cycle collection, including the V1, V2, and V3 models. Featuring removable batteries, Shimano 7-speed gears, and high-performance motors for superior riding experience."
        name="VIR MOBILITY PVT. LTD"
        type="webapp"
        />
      <figure className="d-none d-md-block">
        <img loading="lazy" src="/images/product_listing_banner.jpg" className="img-fluid w-100" alt="VIR V3" />
      </figure>
      <figure className="d-block d-md-none">
        <img loading="lazy" src="/images/mobile_product_listing_banner.jpg" className="img-fluid w-100" alt="VIR V3" />
      </figure>
      <div id="prod-listing" className="prod-listing container-fluid">
        {/* <div className="prod-listing-filters">
          <span 
            className={prodType === 'bike' ? 'prod-listing-filters-item active' : 'prod-filters-item'}
            onClick={() => setProdType('bike')}>
              Bikes
          </span>
          <span 
            className={prodType !== 'bike' ? 'prod-listing-filters-item active mx-3' : 'prod-filters-item mx-3'}
            onClick={() => setProdType('accessories')}>
              Accessories
          </span>
        </div> */}
        <div className="prod-listing-wrapper">
          {
            products && 
            products.map(product => (
              <ProductCard key={product._id+'p'} product={product} />
            ))
          }
        </div>
      </div>
    </>
  )
}

export default ProductListing;